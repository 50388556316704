@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	color: #fff;
	background-color: #1a1350;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

@layer utilities {
	.as-text {
		-moz-appearance: textfield;
	}
	.as-text::-webkit-outer-spin-button,
	.as-text::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
